@use '@angular/material' as mat;

@include mat.core();

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



/* -----------------------------------------------------------
        BASE SETUP
--------------------------------------------------------------  */

$my-primary: mat.define-palette(mat.$gray-palette, 500);
$my-accent: mat.define-palette(mat.$gray-palette, 500);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 density: 0,
));

@include mat.all-component-themes($my-theme);

$primary: #573392;

@import "../node_modules/bootstrap/scss/bootstrap";

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html,body {
    /*background-color: #fff;
    color: #3b246b;
    font-family: 'Lato', 'Arial';
    font-weight: 300;
    font-size: 20px;*/
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    width: "100%";

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    box-sizing: border-box;
	color: #444444;
    font-size: 15px;
    line-height:17px;
    font-weight:400;
    background-color: #fff;
    /*background-color: #f3f2f0;*/
}


.clearfix { zoom:1}
.clearfix:after {
    content: '.';
    clear: both;
    display: block;
    height: 0;
}


.a-right {
    text-decoration: underline !important;
    padding-left: 10px;
    cursor: pointer;
}

.alt-color {
    color: white;
}

.left-line {
    border-left: 1px solid gray;
}

.input-one-line {
    white-space: nowrap;
    display:inline-block !important;
    height: 34px;
}

.cbx-width {
    width: 20px;
}

.cbx-text {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    margin-top: 11px;
    height: 34px;
    font-weight: 700;
}

.dash-body {
    top: 0px;
    padding-top: 110px;
    margin: 0;
}

.dash-body2 {
    background-color: #f3f2f0;
    padding-bottom: 10px;
}

/* -----------------------------------------------------------
        REUSABLE - BUTTONS
--------------------------------------------------------------  */
.btn:link,.btn:visited,input[type=submit] {
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    -webkit-transition: background-color 0.5s, border 0.5s, color 0.5s;
    transition: background-color 0.5s, border 0.5s, color 0.5s;
}

.btn-full:link,.btn-full:visited,input[type=submit] {
    background-color: #f1c40f;
    border: 1px solid #f1c40f;
    color: #fff;
    margin-right: 15px;
}

.btn-full:hover,.btn-full:active {
    border: 1px solid #cf6d17;
    background-color: #cf6d17;
    color: #fff;
}

.btn-ghost:link,.btn-ghost:visited {
    border: 1px solid #f1c40f;
    color: #f1c40f;
}

.btn-ghost:hover,.btn-ghost:active {
    border: 1px solid #cf6d17;
    color: #fff;
}

.btn-std:link,.btn-std:visited {
    background-color: #6637C3;
    border: 1px solid #561fc4;
    color: #fff;
    margin-right: 15px;
}

.btn-std:hover,.btn-std:active {
    border: 1px solid #561fc4;
    background-color: #886bc4;
    color: #fff;
}

.button-primary {
    height: 35px;
    font-size: 14px;
    border: 0px;
    background-color: #623e9c;
    border-radius: 25px;
    color: white;
    padding: 0px 15px 0px 15px;
}

.button-primary:hover, .button-primary:active {
    background-color: #4e3772;
    color: white;
}

.button-secondary {
    height: 35px;
    font-size: 14px;
    border: solid 2px;
    border-radius: 25px;
    border-color: #623e9c;
    color: #623e9c;
    padding: 0px 15px 0px 15px;
}

.button-secondary:hover, .button-secondary:active {
    border-color: #9b84bf;
    color: #9b84bf;
}

.button-tertiary {
    height: 35px;
    font-size: 14px;
    color: #623e9c;
}

.button-tertiary:hover, .button-tertiary:active {
    color: #4e3772;
}


/*   LISTS   */
.list-group-item {
    padding: 5px;
}

.list-group-item:hover {
    background-color:#b2d7fc !important;
}

.list-group-item.active {
    background-color:#82bcf7 !important;
    color: white;
}

// .ng-valid[required], .ng-valid.required  {
//     border-left: 5px solid #42A948; /* green */
//   }
  
// .ng-invalid:not(form)  {
//     border: 2px solid #a94442;
//     border-left: 5px solid #a94442; /* red */
// }
  
form + input[type="radio"] .ng-invalid { 
    color: #a94442 !important;
}

input:invalid ~ .radiobutton {
    background: red;
  }
/*
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/


/*    FORMS    */
.date-picker-row {
    display: block;
    width: 170px;
}

.form-control {
    font-size: 0.8rem;
}

.form-control2 {
    font-size: 0.8rem;
}


.form-control-phone {
    width: 150px;
    font-size: 0.80rem;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.form-control-date {
    width: 100px;
    font-size: 0.80rem;
    display: inline;
}

.form-control-inline {
    display: inline;
}

.form-control-datepicker {
    float: right;
}

.form-control-checkbox {
    width: 100px;
    display: inline-block;
}

/*  order payment status */
.payment-text-PAID {
    color: green;
}

.payment-text-Due {
    color: red;
}


/* sides */
.ff-title-fa {
    font-size: 34px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.ff-title-a {
    text-decoration: none;
    cursor: pointer;
}

.ff-title-no {
    color: gray;
}

@media screen {
    @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
  }

@media print {
    body {
      min-width: auto !important;
    }

    #printContainer > div {
        display: inline;
        overflow-y: visible;
    }
}


/* from home page... */
/*header {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.7)), to(rgba(0,0,0,0.7))), url(../../assets/welcome_bgnd.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(../../assets/welcome_bgnd.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 90vh;
}

.hero-text-box {
    position: absolute;
    width: 1140px;
    top: 50%;
    left: 50%;
    color: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 200%;
    word-spacing: 4px;
    letter-spacing: 1px;
}

.hero-text-box h1 {
    text-transform: uppercase;
}*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&family=Rajdhani:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
    font-family: 'TeXGyreAdventor';
    src: url("/assets/fonts/tex/TeXGyreAdventor-Bold.eot");
    src: url("/assets/fonts/tex/TeXGyreAdventor-Bold.eot?#iefix") format('embedded-opentype'),
        url("/assets/fonts/tex/TeXGyreAdventor-Bold.woff2") format('woff2'),
        url("/assets/fonts/tex/TeXGyreAdventor-Bold.woff") format('woff'),
        url("/assets/fonts/tex/TeXGyreAdventor-Bold.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


html,body{overflow-x: hidden;font-family: 'Roboto', sans-serif;}
ul{list-style: none;padding-left: 0;margin-bottom: 0;}
a:hover{text-decoration: none;}
p{margin-bottom: 0;font-family: "Roboto";}
h1,h2,h3,h4,h5,h6{font-family: 'TeXGyreAdventor';}

.paddr {
    margin-right: 10px;
}

/* Header */
.custmHdr{padding: 60px 0;transition: all 0.5s linear;}

/* Home */
.bx-wrapper{border: none;margin-bottom: 0;box-shadow: none;}
.hmSliderImg img{width: 100%;height: 100%;object-fit: cover;}
.hmSliderContent{left: 0;position: absolute;top: 140px;right: 0;width: 100%;}
.hmSlidrinnr{max-width: 700px;}
.hmSlidrinnr h1{font-size: 66px;line-height: 84px;color: #ffffff;font-weight: 700;margin-bottom: 4rem;}
.hmSlidrinnr .btn{width: 182px;height: 47px;border-radius: 4px;background-color: #9bca3c;font-size: 18px;color: #203000;font-family: 'TeXGyreAdventor';border: 1px solid #9bca3c;transition: all 0.5s linear;}
.hmSlidrinnr .btn:hover{background: transparent;color: #9bca3c;}

.abt{background: url("/assets/bgone.jpg") no-repeat top center / cover;padding: 6rem 0 8rem;}
.abt h2{font-size: 40px;line-height: 58px;color: #142149;font-weight: 700;font-family: 'TeXGyreAdventor';margin-bottom: 2rem;}
.abt .row{align-items: center;}
.abt p{font-size: 16px;line-height: 26px;color: #5e7290;font-weight: 400;max-width: 528px;margin-bottom: 20px;}
.abt .col-md-6:first-child{position: relative;z-index: 1;}
.abt .col-md-6:first-child::after{content: " ";background: url("/assets/pattern-bg.png") no-repeat center center / contain;position: absolute;left: 0;width: 170px;height: 170px;bottom: -70px;left: 10%;z-index: -1;}

.countsInr{filter: drop-shadow(0 0 22.5px rgba(0,0,0,0.05));background-color: #ffffff;position: relative;z-index: 1;padding: 2rem 2rem 5rem;min-height: 350px;}
.countsInr::after{content: " ";background: url("/assets/counts-patterns.png") no-repeat center center / contain;position: absolute;right: 60px;width: 170px;height: 170px;top: 17px;}
.countsInr h2{font-family: 'Rajdhani', sans-serif;font-size: 130px;color: #9bca3c;font-weight: 700;}

.counts{background: url("/assets/countsbg.png") no-repeat top center / cover;padding: 13rem 0 10rem;}
.counts p{font-size: 14px;line-height: 26px;color: #5e7290;font-weight: 400;max-width: 297px;}
.abt2{padding-bottom: 5rem;position: relative;z-index: 1;}
.abt2 .row{align-items: center;}
.abt2 h3{font-size: 30px;line-height: 60px;color: #142149;font-weight: 700;font-family: 'TeXGyreAdventor';}
.abt2::after{content: " ";background: url("//assets/pattern2.png") no-repeat center center / cover;position: absolute;left: 0;top: 50px;width: 166px;height: 200px;}
.abt2 .col-md-5::after{content: " ";background: url("/assets/pattern2.png") no-repeat center center / cover;position: absolute;right: -5px;top: 40%;width: 180px;height: 195px;z-index: -1;}
.featureInnr{text-align: center;}
.ficon{width: 110px;height: 110px;border-radius: 55px;filter: drop-shadow(0.419px 7.989px 10.5px rgba(0,0,0,0.3));background-image: linear-gradient(90deg, #432575 0%, #7b3ddd 100%);padding: 30px;margin: auto;margin-bottom: 1.5rem;}
.featureInnr h3{font-size: 20px;color: #142149;font-weight: 700;margin-bottom: 1rem;}
.featureInnr p{font-size: 16px;line-height: 26px;color: #5e7290;font-weight: 400;max-width:215px;margin: auto;}
.features{padding: 3rem 0 7rem;background: url("/assets/featureBg.jpg") no-repeat center center / cover;}
.features .owl-nav{text-align: center;margin-top: 4rem;}
.features .owl-prev{margin-right: 15px;}
.features .owl-prev:focus,.features .owl-next:focus{outline: 0;}

/* Footer */
.cusftr{background-color: black; color: white;}
.ftrmain .container{border-top: 2px solid #dfeefc;border-bottom: 2px solid #dfeefc;}
.sociaLinks ul{padding: 5rem 0 4rem;display: flex;flex-wrap: wrap;align-items: center;justify-content: center;}
.sociaLinks li a{font-size: 12px;letter-spacing: 2px;color: #9d9d9d;font-weight: 400;font-family: 'Poppins', sans-serif;text-transform: uppercase;display: inline-block;padding: 20px 50px;transition: all 0.5s linear;}
.sociaLinks li{border: 1px solid #464646;border-right: none;}
.sociaLinks li:last-child{border-right: 1px solid #464646;}
.sociaLinks li:hover a{background: rgb(101, 52, 180);color: #fff;}
.ftrLogo{text-align: left; padding-top: 5px; padding-bottom: 5px;} /*;padding-bottom: 5rem;}*/
.copyright p{text-align: center;font-size: 15px;color: white; font-weight: 400;}
.copyright p a{color: white;}
.copyright{padding: 20px 0;}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999999;
  }


/***** WAIT MODAL   ****/
.modalSpinner {
    /*display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    /*padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
}

.wait-loader {
    position: relative;
    left: 50%;
    top: 50%;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid rgb(0,52,104); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
.vh-mid-menu {
    padding: 5px;
    border: 1px solid rgb(209, 209, 209);
    /*border-top: 1px solid rgb(209, 209, 209);
    border-bottom: 1px solid rgb(209, 209, 209);*/
    background-color: rgba(255, 255, 0, 0.123);
    margin-left: -5px !important;
    margin-right: 0px !important;
}

.vh-mid-right {
    text-align: right;
    /*padding-top: 5px;
    font-size: 1.75rem;*/
}

.p-t-98{padding-top:98px;}
.p-b-98{padding-bottom:98px;}
.p-t-60{padding-top:60px;}
.p-b-60{padding-bottom:60px;}
.p-t-30{padding-top:30px;}
.p-b-30{padding-bottom:30px;}

.m-t-98{margin-top:98px;}
.m-b-98{margin-bottom:98px;}
.m-t-60{margin-top:60px;}
.m-b-60{margin-bottom:60px;}
.m-t-30{margin-top:30px;}
.m-b-30{margin-bottom:30px;}

.graybg{background-color: #ebedf2;}
.green-bg{background-color: #6637c3;}


.home-page-title{color: #010101; font-weight: bold;font-family: 'Poppins', sans-serif; font-size: 40px; line-height: 48px; margin-bottom: 35px;}
.header-titlerow{margin-bottom: 25px;}
.home-mid-p {font-size: 16px; line-height: 30px;}


.home-banner{background-image:url("/assets/bnr-img/home-bnr-img.jpg"); background-repeat:no-repeat; background-position:top center; background-size: cover;}
.home-bnr-title{ font-size: 35px; line-height: 60px;  color: #010101; font-weight: bold; margin-bottom: 30px;font-family: 'Poppins', sans-serif;}
.home-banner p{color: #343434; font-size: 18px; line-height: 32px;}
.home-banner .one.col-cmn{padding-right: 100px;}
.home-banner .two img.bnr-right-img{margin-left: -100px;}
.home-container{
    max-width: 1240px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.home-banner-top-full { min-height: 80vh;}
.home-banner-top-other { min-height: 40vh;}
.home-banner-top-thin { min-height: 20vh;}
.home-top-other-left {
    padding-top: 50px;
    padding-left: 50px;
}

.home-body-other-left {
    padding-left: 50px;
}


.service-box{margin-bottom: 35px;}
.service-box-main{background-color: #fff;border-radius: 7px;box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.19) !important; padding: 40px 20px; height: 100%;}
.service-box-main .section-img{margin-bottom: 30px;}
.service-box-main .section-content h2{ color: #000000; font-size: 24px; line-height: 34px; font-weight: 500; font-family: 'Poppins', sans-serif;}

.home-row-two .row .service-box:nth-child(odd){padding-right: 20px;}
.home-row-two .row .service-box:nth-child(even){padding-right: 20px;}


.middle-content-row{align-items: center; /* justify-content: center;*/}

.search-section .search-formDiv{background-color: #fff; margin: 20px 0; padding:15px 20px; border-radius: 6px;}
.search-formDiv-box .search-formDiv-box-col{display: inline-block;}
.search-formDiv-box .search-formDiv-box-col span{color: #706f6f; padding-right: 10px;}
.search-formDiv-box input[type="text"], .search-formDiv-box select{border: 1px solid #7f7f7f; border-radius: 35px; padding: 8px 15px; background-color: transparent; width: 160px; margin-right: 15px;}
.search-formDiv-box-date{width: 120px !important; margin-right: 2px !important;}
.search-formDiv-box input[type="submit"]{background-color: #9bca3c; border: none; color: #fff; border-radius: 35px; padding: 10px 32px; font-weight: bold; cursor: pointer;}
/*.search-formDiv-box input.searchdate-from, .search-formDiv-box input.searchdate-to{background-image: url(../image/calender-icon.png); background-repeat: no-repeat; background-position: center right 5px;}*/
.searchbtng {background-color: #9bca3c !important; border: none; color: #fff; border-radius: 35px; padding: 10px 32px; font-weight: bold !important; cursor: pointer;}
.searchbtng:disabled {background-color: #9bca3c88; border: none; color: #fff; border-radius: 35px; padding: 10px 32px; font-weight: bold; cursor: pointer;}
.searchbtno {background-color: #3c9bca !important; border: none; color: #fff; border-radius: 35px; padding: 10px 32px; font-weight: bold; cursor: pointer;}
.searchbtog {background-color: #ca3c9b !important; border: none; color: #fff; border-radius: 35px; padding: 10px 32px; font-weight: bold; cursor: pointer;}

.search-box2 {border: 1px solid #7f7f7f; border-radius: 35px; padding: 8px 15px; background-color: transparent; width: 160px; margin-right: 15px;}

.header-border .container{border-bottom: 1px solid #c3c2c0; margin-bottom: 20px;}

.package-section{padding-bottom: 0px;}
.packageDiv{background-color: #fff;}
.packageDiv-full{padding-bottom:15px; max-height: calc(100vh - 150px); min-height: calc(100vh - 150px);}
.packageDiv-tbl{padding-bottom:15px; max-height: calc(100vh - 265px); min-height: calc(100vh - 265px);}
.packageDiv-full-bo{padding-bottom:15px;}


table.package-tbl {border-collapse: collapse;}
table.package-tbl tr td { border: 1px solid #ddd;  padding: 5px 8px; font-size: 12px;}

table.package-tbl tr:nth-child(even){background-color: #f9f9f9;}

table.package-tbl thead{display: table; table-layout: fixed; width: calc(100% - 25px);}
table.package-tbl thead tr td{border: none; font-weight: 600; font-size: 13px; padding-bottom: 10px;}
table.package-tbl tbody { display: block; width: 100%;}
table.package-tbl tr {display: table; table-layout: fixed; width: 100%;}
table.package-tbl tr td i{color: #3c3c3c; margin-left: 5px;}
table.package-tbl tr td span{font-size: 11px; padding-right: 15px;}

.th-td-sorter { cursor: pointer;}
/*
table.package-tbl {border-collapse: collapse;}
table.package-tbl tr td {  border: 1px solid #ddd;  padding: 5px 8px; font-size: 12px;}
table.package-tbl  tr td {  border: 1px solid #ddd;  padding: 5px 8px; font-size: 12px;}
table.package-tbl  tr:nth-child(even){background-color: #f9f9f9;}
table.package-tbl tr:first-child td{border: none; font-weight: 600; font-size: 13px; padding-bottom: 10px;}
table.package-tbl  tr:first-child td{border: none; font-weight: 600; font-size: 13px; padding-bottom: 10px;}
table.package-tbl tr td i{color: #3c3c3c; margin-left: 5px;}
table.package-tbl  tr td span{font-size: 11px; padding-right: 15px;}
*/


  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .full-width-dialog {
    max-width: 100vw !important;
  }
  


@media (max-width: 1310.98px){
    .hmSliderContent{left: 0;position: absolute;top: 140px;right: 0;width: 100%;}
    .hmSlidrinnr h1{font-size: 40px;margin-bottom: 2rem;line-height: 40px;}
}

@media (max-width: 1199.98px){
   .hmSliderContent{left: 0;position: absolute;top: 110px;right: 0;width: 100%;}
   .hmSlidrinnr {max-width: 400px;}
   .hmSlidrinnr h1{font-size: 35px;margin-bottom: 2rem;line-height: 40px;}
   .custmHdr{padding: 20px 0!important;}
   .abt .col-md-6{z-index: 1;}
   .countsInr{min-height: 384px;}
}

@media (max-width: 991.98px){
    
    .site-logo img{height: 48px;}
    .hmSliderContent{left: 0;position: absolute;top: 100px;right: 0;width: 100%;}
    .hmSlidrinnr h1{font-size: 30px;margin-bottom: 2rem;line-height: 40px;}
    .abt{background: url("/assets/bgone.jpg") no-repeat top center / cover;padding: 3rem 0 3rem;}
    .abt .col-md-6:first-child::after{width: 120px;height: 120px;}
    .abt h2{font-size: 30px;line-height: 36px;}
    .counts{padding: 3rem 0 3rem;}
    .counts .col-md-4{margin-bottom: 20px;}
    .countsInr{min-height: 459px;}
    .abt2::after{display: none;}
    .abt2 h3{font-size: 25px;line-height: 39px;}
    .features{padding: 1rem 0 3rem;}
    .features .owl-nav{margin-top: 2rem;}
    .sociaLinks ul{padding: 3rem 0 3rem;}
    .sociaLinks li{border-right: 1px solid #464646;}
    .sociaLinks li a{padding: 15px 20px;}
    .ftrLogo{padding-bottom: 3rem;}
}

@media (max-width: 870.98px){
 
}

@media (max-width: 767.98px) { 
    .abt .col-md-6:first-child{margin-bottom: 30px;}
    .countsInr{min-height: 350px;}
    .abt2 h3{margin-bottom: 2rem;}
}

@media (max-width: 600px) {
  .hmSliderImg{height: 500px;}
}

@media (max-width: 575.98px) {
    
}

@media (max-width: 550.98px) {
   

}

@media (max-width: 480px) {
   
}
  
@media (max-width: 357px) {
  
}

@media (max-width: 321.98px) {
   
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1400px;
    }
}

.page-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.page-content {
    width: 80%;
    margin-top: 55px;
}

.page-content-2 {
    width: 80%;
    margin-top: 13px;
}
